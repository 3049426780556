import * as React from "react";
import TemplatePage1 from "@components/TemplatePage/TemplatePage";
import styled from "styled-components";
import { FaPlus } from "react-icons/fa";
import { Link } from "gatsby";
import img1 from '@images/fotos/img1.jpg';
import img2 from '@images/fotos/img2.jpg';
import img3 from '@images/fotos/img3.jpg';
import adorno from '@images/objetivos/adorno2.png';
import Button from "@components/Button/Button";





// markup
const IndexPage = () => {
  return (
    <TemplatePage1
      title={"QuEST LAC: Objetivos"}
      adorno={<Adorno />}
      content={(

        <HexaWrapper>
          <HexaRowPair>
            <Hexagon bg={img1}>
              <TextZone>
                <DescZone>
                  <BigNumber>
                    <svg version="1.1" id="26fffe9e-f5ab-4725-8366-7c4c6f74c60e_svg" x="0px" y="0px" viewBox="8.77299976348877 0.10000000149011612 14.455000877380371 31.799999237060547" enable-background="new 0 0 32 32" preserveAspectRatio="none" width="100%" height="100%">
                      <g>
                        <g>
                          <path d="M23.227,31.9H16.04V10.633H8.773V5.563l0.461-0.02c1.874-0.082,3.197-0.207,3.934-0.371
			c1.119-0.247,2.037-0.743,2.73-1.477c0.469-0.495,0.832-1.173,1.079-2.016c0.177-0.635,0.215-0.948,0.215-1.098V0.1h6.036V31.9z
			 M17.004,30.936h5.26V1.064h-4.155C18.068,1.306,18,1.596,17.903,1.944c-0.293,0.998-0.731,1.808-1.306,2.413
			c-0.827,0.875-1.911,1.466-3.221,1.755c-0.737,0.165-1.93,0.287-3.639,0.372v3.185h7.268V30.936z" fill="#084480"></path>
                        </g>
                      </g>
                    </svg>
                  </BigNumber>
                  <p> Consolidarse como la red latinoamericana QuEST LAC </p>
                </DescZone>
                <Button to={"/acerca/objetivos/objetivo1"} bg={"var(--color2)"} size={"14px"}> Ver más </Button>
              </TextZone>
            </Hexagon>
            <Hexagon bg={img2}>
              <TextZone>
                <DescZone>
                  <BigNumber>
                    <svg version="1.1" id="df6a6a3a-edfa-46a1-88ce-7f6127f96af9_svg" x="0px" y="0px" viewBox="5.172999858856201 0.10100007057189941 21.65399932861328 31.798999786376953" enable-background="new 0 0 32 32" preserveAspectRatio="none" width="100%" height="100%">
                      <g>
                        <path d="M26.826,31.9H5.173l0.058-0.537c0.229-2.125,0.928-4.169,2.078-6.074c1.133-1.876,3.389-4.389,6.703-7.466
		c2.613-2.435,4.232-4.098,4.81-4.945c0.749-1.124,1.13-2.246,1.13-3.329c0-1.172-0.308-2.075-0.915-2.684
		c-0.616-0.616-1.461-0.915-2.579-0.915c-1.103,0-1.949,0.316-2.586,0.965c-0.639,0.652-1.016,1.789-1.119,3.378l-0.033,0.501
		l-6.85-0.685l0.053-0.486c0.362-3.36,1.531-5.819,3.475-7.307c1.921-1.47,4.345-2.215,7.206-2.215c3.143,0,5.659,0.869,7.474,2.583
		c1.824,1.721,2.749,3.89,2.749,6.448c0,1.436-0.261,2.824-0.777,4.126c-0.509,1.285-1.325,2.647-2.425,4.048
		c-0.725,0.924-2.04,2.261-3.908,3.974c-1.834,1.681-3.009,2.811-3.493,3.358c-0.247,0.278-0.471,0.555-0.67,0.826l11.271,0.001
		V31.9z M6.263,30.931h19.593v-4.497l-12.04-0.001l0.422-0.728c0.322-0.556,0.746-1.131,1.261-1.712
		c0.506-0.571,1.706-1.725,3.563-3.428c1.829-1.679,3.108-2.977,3.8-3.859c1.042-1.328,1.812-2.609,2.286-3.807
		c0.471-1.188,0.709-2.456,0.709-3.769c0-2.312-0.8-4.191-2.444-5.742c-1.631-1.538-3.922-2.319-6.81-2.319
		c-2.642,0-4.869,0.678-6.617,2.015c-1.633,1.25-2.655,3.32-3.041,6.157l4.882,0.489c0.167-1.574,0.62-2.748,1.351-3.494
		c0.817-0.833,1.92-1.255,3.278-1.255c1.369,0,2.467,0.404,3.264,1.2c0.796,0.798,1.2,1.931,1.2,3.369
		c0,1.278-0.436,2.581-1.296,3.871c-0.631,0.923-2.249,2.594-4.953,5.113c-3.249,3.016-5.447,5.458-6.533,7.258
		C7.159,27.412,6.53,29.138,6.263,30.931z" fill="#084480"></path>
                      </g>
                    </svg>
                  </BigNumber>
                  <p>  Promover el desarrollo de capacidades de investigación en sistemas de salud en la región de LAC </p>
                </DescZone>
                <Button to={"/acerca/objetivos/objetivo2"} bg={"var(--color2)"} size={"14px"}> Ver más </Button>
              </TextZone>
            </Hexagon>
          </HexaRowPair>
          <HexaRowOdd>
            <Hexagon bg={img3}>
              <TextZone>
                <DescZone>
                  <BigNumber>
                    <svg version="1.1" id="6b9f9937-df0e-43ae-9507-34c1b31ce99f_svg"  x="0px" y="0px" viewBox="5.389999866485596 0.10000000149011612 21.22100067138672 31.799999237060547" enable-background="new 0 0 32 32" preserveAspectRatio="none" width="100%" height="100%">
                      <g>
                        <g>
                          <path d="M15.938,31.9c-4.307,0-7.352-1.477-9.051-4.391c-0.884-1.537-1.381-3.556-1.477-6.006l-0.02-0.501h6.639v0.482
			c0,1.119,0.174,2.05,0.517,2.765c0.616,1.275,1.72,1.896,3.372,1.896c1.011,0,1.868-0.345,2.622-1.056
			c0.745-0.706,1.109-1.709,1.109-3.069c0-1.771-0.668-2.917-2.043-3.503c-0.818-0.343-2.164-0.518-3.999-0.518h-0.482V12.83
			l0.475-0.007c1.788-0.027,3.034-0.2,3.707-0.513c1.101-0.501,1.634-1.506,1.634-3.075c0-1.028-0.279-1.834-0.852-2.465
			c-0.562-0.615-1.345-0.915-2.394-0.915c-1.203,0-2.053,0.367-2.601,1.123c-0.572,0.79-0.85,1.88-0.824,3.24l0.009,0.491H5.918
			l0.018-0.499c0.055-1.524,0.313-2.994,0.769-4.368c0.506-1.256,1.29-2.402,2.34-3.434c0.807-0.753,1.765-1.329,2.858-1.721
			C12.985,0.297,14.322,0.1,15.878,0.1c2.904,0,5.287,0.786,7.083,2.338C24.778,4.006,25.7,6.134,25.7,8.761
			c0,1.875-0.554,3.48-1.648,4.773c-0.395,0.462-0.805,0.844-1.225,1.143c0.42,0.249,0.891,0.623,1.429,1.127
			c1.563,1.481,2.355,3.516,2.355,6.051c0,2.643-0.907,4.999-2.695,7.002C22.112,30.876,19.428,31.9,15.938,31.9z M6.399,21.966
			c0.134,2.065,0.578,3.765,1.323,5.06c1.533,2.63,4.221,3.91,8.216,3.91c3.204,0,5.645-0.916,7.257-2.721
			c1.626-1.822,2.45-3.962,2.45-6.361c0-2.26-0.691-4.061-2.051-5.35c-1.205-1.13-1.75-1.252-1.929-1.252h-2.532l2.355-0.93
			c0.621-0.245,1.237-0.721,1.829-1.415c0.953-1.126,1.418-2.484,1.418-4.148c0-2.368-0.787-4.198-2.405-5.594
			c-1.616-1.396-3.786-2.103-6.452-2.103c-1.445,0-2.673,0.178-3.649,0.53c-0.969,0.348-1.816,0.856-2.518,1.51
			c-0.946,0.93-1.656,1.966-2.102,3.07c-0.368,1.112-0.6,2.318-0.685,3.572h4.385c0.044-1.352,0.381-2.471,1.005-3.332
			c0.731-1.009,1.869-1.521,3.381-1.521c1.317,0,2.362,0.414,3.106,1.23c0.732,0.806,1.103,1.853,1.103,3.113
			c0,1.959-0.739,3.288-2.195,3.95c-0.736,0.343-1.924,0.538-3.621,0.591v3.265c1.742,0.03,3.018,0.223,3.892,0.59
			c1.747,0.743,2.631,2.221,2.631,4.39c0,1.616-0.474,2.884-1.411,3.769c-0.928,0.875-2.033,1.319-3.284,1.319
			c-2.031,0-3.457-0.822-4.241-2.442c-0.353-0.738-0.555-1.645-0.601-2.701H6.399z" fill="#084480"></path>
                        </g>
                      </g>
                    </svg>
                  </BigNumber>
                  <p>  Realizar investigación pertinente para la región y compartir evidencia con los responsables políticos locales</p>
                </DescZone>
                <Button to={"/acerca/objetivos/objetivo3"} bg={"var(--color2)"} size={"14px"}> Ver más </Button>
              </TextZone>
            </Hexagon>
          </HexaRowOdd>
        </HexaWrapper>

      )}
    />
  )
};

export default IndexPage;



const HexaWrapper = styled.div`
background-color: "#FFFFFFDD";
position:relative;
width:100%;
@media only screen and (min-width:768px){
display: flex;
flex-direction: column;
align-items:flex-end;
}


`;

const BigNumber = styled.h2`
height: 60px;
max-width:40px;
font-weight: bolder;
color: white;
text-shadow: 0px 0px 30px var(--tema4);
svg{
  width:100%;
}
`;

const DescZone = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
font-size:16px;
font-weight: bold;
color: var(--tema4);

p{
  width:80%;
  padding:15px;
}
`

const HexaRowPair = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
width:100%;
@media only screen and (min-width:960px){
  flex-direction: row;
  justify-content: flex-end;
  height:230px;
  transform: translateY(30px);
}


`;

const HexaRowOdd = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
width:100%;
@media only screen and (min-width:960px){
  flex-direction: row;
  justify-content: flex-end;
  height:230px;
  transform: translate(-150px,30px);
}
`;


const TextZone = styled.div`
width:100%;
height:100%;
padding: 20px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
text-align: center;
clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
z-index: 99999;

text-shadow: 0px 0px 4px #FFFFFF;

span{
  font-size: 24px;
}


:hover{
opacity: 1;
}

@media only screen and (min-width:768px){
  span{
    font-size: 70px;
  }
  a{
    font-size: 18px;
  }
}

`

const Hexagon = styled.div`
position: relative;
clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
background-size: cover;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
text-align: center;
height: 300px;
max-height:300px;
width: 300px;
max-width:300px;


::before{
  content: "";
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height:100%;
  background-color: ${props => props.bgc ? props.bgc : ""};
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  background-image: url(${props => props.bg ? props.bg : ""});
  background-size: cover;
  filter: opacity(40%);
}

:hover{
  ::before{
  content: "";
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height:100%;
  background-color: ${props => props.bgc ? props.bgc : "#FFFFFFFF"};
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  background-image: url(${props => props.bg ? props.bg : ""});
  background-size: cover;
  filter: opacity(60%);
}
}

`

const Adorno = styled.div`
position: absolute;
top: 0;
left: 0;
height:110%;
@media only screen and (min-width:960px){
  height:110%;
  width: 40%;
  background-position-x:-400px;
  background-image: url(${adorno});
background-size: cover;
background-repeat: no-repeat;
}
@media only screen and (min-width:1200px){
  height:110%;
  width: 40%;
  background-position-x:-200px;
  background-image: url(${adorno});
background-size: cover;
background-repeat: no-repeat;
}
@media only screen and (min-width:1430px){
  height:110%;
  width: 40%;
  background-image: url(${adorno});
background-size: cover;
background-repeat: no-repeat;
}
@media only screen and (min-width:1700px){
  height:110%;
  width: 50%;
  background-position-x:0%;
  background-image: url(${adorno});
background-size: cover;
background-repeat: no-repeat;
}
`;