import { Link } from "gatsby";
import styled from "styled-components";

const Button = styled(Link)`
padding: 10px 15px;
font-weight: normal;
color: white;
background-color: ${props=>props.bg?props.bg:""};
font-size: ${props=>props.size?props.size:"20px"};
`;

export default Button;
